
import { defineComponent, ref, PropType, toRefs, watch } from 'vue';

import ImageUploader, { UploadImageEvent } from './ImageUploader.vue';

export interface UploadPhotoEvent {
  index: number
  photo: UploadImageEvent
};

export interface DeletePhotoEvent {
  index: number
};

export default defineComponent({
  components: {
    ImageUploader
  },
  props: {
    sources: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ['upload', 'delete'],
  setup(props, { emit }) {
    const { sources } = toRefs(props);
    const photoSources = ref<string[]>([...sources.value, '']);
    watch(sources, (sources) => {
      photoSources.value = [...sources, ''];
    });

    const handelUpload = (event: UploadPhotoEvent) => {
      emit('upload', event);
    };

    const handleDelete = ({ index }: DeletePhotoEvent) => {
      emit('delete', { index });
    };

    return {
      photoSources,
      handleDelete,
      handelUpload
    };
  }
});
