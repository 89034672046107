import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab66a70c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-uploader-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageUploader = _resolveComponent("ImageUploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photoSources, (photoSource, index) => {
      return (_openBlock(), _createBlock(_component_ImageUploader, {
        key: index,
        cropped: false,
        removable: "",
        source: photoSource,
        onUpload: ($event: any) => (_ctx.handelUpload({index, photo: $event})),
        onDelete: ($event: any) => (_ctx.handleDelete({index}))
      }, null, 8, ["source", "onUpload", "onDelete"]))
    }), 128))
  ]))
}